import { useRef, useEffect, Fragment, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import { Typography, MenuItem } from "@material-ui/core";
import LocalizedText from "../reusable/LocalizedText";
import classNames from "classnames";
import { useIntl } from "react-intl";
import {
  selectTranscript,
  selectGreekTranslation,
  selectHindiTranslation,
  selectItalianTranslation,
  selectKoreanTranslation,
  selectSpanishTranslation
} from "../../selectors";
import TranscriptButton from "./TranscriptButton";
import CTXSelect, { CTXInputBase } from "../reusable/CTXSelect";

const useTranscriptSectionStyles = makeStyles(
  {
    speakersHeading: {
      fontSize: "1.1rem"
    },
    partialContent: {
      color: "#aaa"
    }
  },
  { name: "TranscriptSection" }
);

export function TranscriptSection({
  speakers,
  content,
  partialContent,
  className
}) {
  const classes = useTranscriptSectionStyles();

  return (
    <div className={className}>
      {speakers && (
        <Typography className={classes.speakersHeading} variant="h6">
          {speakers}
        </Typography>
      )}
      <Typography>
        {content}
        {partialContent && (
          <span className={classes.partialContent}>{partialContent}</span>
        )}
      </Typography>
    </div>
  );
}

const useTranscriptStyles = makeStyles(
  theme => ({
    root: {
      display: "flex",
      flexDirection: "column",
      // The minHeight is needed so that the bottom
      // of the transcript div isn't cut off.
      minHeight: 0,
      height: "100%",
      padding: "5px 20px 0 20px",
      color: theme.colors.primaryTextColor
    },
    heading: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      marginBottom: "5px"
    },
    buttons: {
      display: "flex",
      gap: "10px"
    },
    selection: {
      display: "flex",
      alignItems: "center",
      gap: "10px",
      marginBottom: "5px"
    },
    headingDivider: {
      borderTop: `3px solid ${theme.colors.secondaryMainColor}`
    },
    transcript: {
      paddingRight: "8px",
      overflowY: "auto"
    },
    scrollBar: {
      "&::-webkit-scrollbar": {
        width: "8px",
        height: "8px"
      },
      "&::-webkit-scrollbar-thumb": {
        background: theme.colors.secondaryMainColor,
        borderRadius: "4px",
        border: "none"
      },
      "&::-webkit-scrollbar-track-piece": {
        background: theme.colors.sideBarBackgroundColor,
        borderRadius: "4px"
      },
      "&::-webkit-scrollbar-corner": {
        background: theme.colors.sideBarBackgroundColor
      },
      scrollbarColor: `${theme.colors.secondaryMainColor} ${theme.colors.popoverBackgroundColor}`,
      scrollbarWidth: "thin"
    },
    transcriptSection: {
      margin: "8px 0"
    },
    divider: {
      borderTop: "1px solid #808080"
    }
  }),
  { name: "Transcript" }
);

export default function Transcript({
  stylesCopied,
  copyButtonDisplayed = true
}) {
  const classes = useTranscriptStyles();
  const intl = useIntl();

  const [tabIndex, setTabIndex] = useState(0);

  const conferenceName = useSelector(state => state.session.confName);
  const conferenceStartTime = useSelector(state => state.session.startTime);
  const transcript = useSelector(state => {
    switch (tabIndex) {
      case 0:
        return selectTranscript(state, intl);
      case 1:
        return selectGreekTranslation(state);
      case 2:
        return selectHindiTranslation(state);
      case 3:
        return selectItalianTranslation(state);
      case 4:
        return selectKoreanTranslation(state);
      case 5:
        return selectSpanishTranslation(state);
      default:
        return [];
    }
  });

  const transcriptRef = useRef(null);

  useEffect(() => {
    transcriptRef.current.scrollTop = transcriptRef.current.scrollHeight;
  }, [stylesCopied, tabIndex]);
  useEffect(() => {
    if (isNearBottom()) {
      transcriptRef.current.scrollTop = transcriptRef.current.scrollHeight;
    }
  }, [transcript]);

  function isNearBottom() {
    return (
      transcriptRef.current.scrollHeight -
        transcriptRef.current.scrollTop -
        transcriptRef.current.offsetHeight <
      100
    );
  }

  function handleLanguageChange(event) {
    setTabIndex(event.target.value);
  }

  let languageName;
  switch (tabIndex) {
    case 1:
      languageName = " Greek";
      break;
    case 2:
      languageName = " Hindi";
      break;
    case 3:
      languageName = " Italian";
      break;
    case 4:
      languageName = " Korean";
      break;
    case 5:
      languageName = " Spanish";
      break;
    default:
      languageName = "";
      break;
  }

  return (
    <div className={classes.root}>
      <div className={classes.heading}>
        <LocalizedText value="transcript" variant="h6" />
        <div className={classes.buttons}>
          {copyButtonDisplayed && (
            <TranscriptButton type="copy" transcript={transcript}>
              <LocalizedText value="copy" />
            </TranscriptButton>
          )}
          <TranscriptButton
            type="download"
            filename={
              conferenceName +
              " " +
              new Date(conferenceStartTime).toLocaleString("en-US", {
                timeZoneName: "short"
              }) +
              languageName +
              ".txt"
            }
            transcript={transcript}
          >
            <LocalizedText value="download" />
          </TranscriptButton>
        </div>
      </div>
      <div className={classes.selection}>
        <Typography>Translation:</Typography>
        <CTXSelect
          value={tabIndex}
          onChange={handleLanguageChange}
          input={<CTXInputBase fullWidth />}
          height="large"
        >
          <MenuItem value={0}>None</MenuItem>
          <MenuItem value={1}>Greek</MenuItem>
          <MenuItem value={2}>Hindi</MenuItem>
          <MenuItem value={3}>Italian</MenuItem>
          <MenuItem value={4}>Korean</MenuItem>
          <MenuItem value={5}>Spanish</MenuItem>
        </CTXSelect>
      </div>
      <div className={classes.headingDivider} />
      <div
        ref={transcriptRef}
        className={classNames(classes.transcript, classes.scrollBar)}
      >
        {transcript.map((section, index) => (
          <Fragment key={index}>
            <TranscriptSection
              speakers={section.speakers}
              content={section.content}
              partialContent={section.partialContent}
              className={classes.transcriptSection}
            />
            {tabIndex === 0 && index < transcript.length - 1 && (
              <div className={classes.divider} />
            )}
          </Fragment>
        ))}
      </div>
    </div>
  );
}
