import TextButton from "../reusable/TextButton";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(
  {
    button: {
      minWidth: "initial",
      minHeight: "initial",
      marginBottom: "initial"
    }
  },
  { name: "TranscriptButton" }
);

export default function TranscriptButton({
  children,
  type,
  filename,
  transcript
}) {
  const classes = useStyles();

  function getTranscript() {
    const transcriptText = [];

    for (const section of transcript) {
      if (section.content) {
        if (section.speakers) {
          transcriptText.push(section.speakers + "\n");
        }
        transcriptText.push(section.content);
        transcriptText.push("\n\n");
      }
    }

    transcriptText.pop();
    transcriptText.push("\n");
    return transcriptText.join("");
  }

  function handleClick() {
    if (type === "copy") {
      navigator.clipboard.writeText(getTranscript());
    } else if (type === "download") {
      const blob = new Blob([getTranscript()], { type: "text/plain" });

      const tempElement = document.createElement("a");
      tempElement.href = URL.createObjectURL(blob);
      tempElement.download = filename;

      document.body.appendChild(tempElement);

      tempElement.click();

      document.body.removeChild(tempElement);
    }
  }

  const enabled = transcript.some(section => section.content);

  return (
    <TextButton
      className={classes.button}
      onClick={handleClick}
      disabled={!enabled}
    >
      {children}
    </TextButton>
  );
}
