import { createTheme, alpha } from "@material-ui/core/styles";

export const createConnectNowStyle = theme => {
  return {
    rightBarPopper: {
      root: {
        zIndex: 12,
        '&[x-placement*="left"] $arrow': {
          right: 0,
          marginRight: "-1em",
          width: "1em",
          "&::before": {
            content: '""',
            margin: "auto",
            display: "block",
            width: 0,
            height: 0,
            borderStyle: "solid",
            borderWidth: "1.2em 0 1.2em 1em",
            borderColor: `transparent transparent transparent ${theme.popoverBackgroundColor}`
          },
          position: "absolute",
          fontSize: "1em",
          opacity: theme.opacity
        }
      },
      popperIconButton: {
        fontSize: "2.5em",
        width: "100%"
      },
      layout: {
        backgroundColor: theme.popoverBackgroundColor,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "330px",
        color: theme.primaryTextColor,
        borderRadius: "10px",
        opacity: theme.opacity,
        padding: "10px 20px 5px 20px"
      },
      closeHeader: {
        width: "100%",
        textAlign: "right"
      },
      header: {
        width: "100%"
      },
      content: {
        width: "100%"
      },
      arrow: {}
    },
    leftBarPopper: {
      root: {
        zIndex: 2,
        '&[x-placement*="right"] $arrow': {
          left: 0,
          marginLeft: "-1em",
          width: "1em",
          "&::before": {
            content: '""',
            margin: "auto",
            display: "block",
            width: 0,
            height: 0,
            borderStyle: "solid",
            borderWidth: "1.2em 1em 1.2em 0",
            borderColor: `transparent ${theme.primaryBackgroundColor} transparent transparent`
          },
          position: "absolute",
          fontSize: "1em",
          opacity: theme.opacity
        }
      },
      popperIconButton: {
        fontSize: "2.5em"
      },
      layout: {
        backgroundColor: theme.primaryBackgroundColor,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "330px",
        color: theme.primaryTextColor,
        borderRadius: "10px",
        opacity: theme.opacity,
        padding: "10px 20px 5px 20px"
      },
      closeHeader: {
        width: "100%",
        textAlign: "right"
      },
      header: {
        width: "100%"
      },
      content: {
        width: "100%"
      },
      arrow: {}
    },
    basePageLayout: {
      root: {
        height: "100%",
        width: "100%",
        display: "grid",
        gridTemplateColumns: "64px 1fr 64px",
        gridTemplateRows: "64px 1fr",
        msGridColumns: "64px 1fr 64px",
        msGridRows: "64px 1fr"
      },
      headerSpacer: {
        gridColumn: "1 / span 3",
        gridRow: "1",
        msGridColumn: "1 / span 3",
        msGridRow: "1"
      }
    },
    mobileBasePageLayout: {
      root: {
        height: "100%",
        width: "100%",
        display: "grid",
        gridTemplateColumns: "0px 1fr 0px",
        gridTemplateRows: "64px 1fr",
        msGridColumns: "0px 1fr 0px",
        msGridRows: "64px 1fr"
      },
      headerSpacer: {
        gridColumn: "1 / span 3",
        gridRow: "1",
        msGridColumn: "1 / span 3",
        msGridRow: "1"
      }
    },
    portalPageLayout: {
      root: {
        height: "100%",
        width: "100%",
        display: "grid",
        gridTemplateColumns: "1fr",
        gridTemplateRows: "64px 1fr",
        msGridColumns: "1fr",
        msGridRows: "64px 1fr"
      },
      portalHeaderSpacer: {
        gridColumn: "1",
        msGridColumn: "1"
      }
    },
    portalViewLayout: {
      root: {
        height: "100%",
        width: "100%",
        display: "grid",
        gridTemplateColumns: "200px 1fr",
        msGridColumns: "200px 1fr"
      }
    }
  };
};

export const createConnectNowTheme = theme => {
  return createTheme({
    typography: {
      useNextVariants: true
    },
    palette: {
      type: "dark",
      primary: {
        main: theme.primaryMainColor
      },
      secondary: {
        main: theme.secondaryMainColor
      },
      error: {
        main: theme.errorMainColor
      }
    },
    overrides: {
      MuiCssBaseline: {
        "@global": {
          html: {
            height: "100%"
          },
          body: {
            height: "100%",
            fontSize: "1rem",
            lineHeight: 1.1
          }
        }
      },
      MuiInput: {
        root: {
          color: theme.primaryTextColor
        },
        underline: {
          "&:before": {
            borderBottomColor: theme.primaryTextColor
          },
          "&:hover:not(.Mui-disabled):before": {
            borderBottomColor: theme.primaryTextColor
          },
          "&:after": {
            borderBottomColor: theme.primaryTextColor
          }
        }
      },
      MuiInputLabel: {
        root: {
          color: alpha(theme.primaryTextColor, 0.7),
          "&.Mui-focused:not(.Mui-error)": {
            color: theme.primaryTextColor
          }
        }
      },
      MuiAppBar: {
        root: {
          height: "64px"
        }
      },
      MuiToolbar: {
        root: {
          justifyContent: "space-between"
        }
      },
      MuiMenu: {
        paper: {
          backgroundColor: theme.primaryBackgroundColor,
          color: theme.primaryTextColor
        }
      },
      MuiList: {
        padding: {
          paddingTop: "0px",
          paddingBottom: "0px"
        }
      },
      MuiTypography: {
        caption: {
          color: theme.primaryTextColor
        }
      },
      MuiPaper: {
        root: {
          color: theme.primaryTextColor
        }
      }
    },
    shadows: Array(25).fill("none"),
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 850,
        lg: 1280,
        xl: 1920
      }
    }
  });
};

export const getTextColor = (theme, disabled) => {
  return disabled
    ? theme.colors.secondaryTextColor
    : theme.colors.primaryTextColor;
};
