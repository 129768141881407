import { Fragment, useMemo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { FormControlLabel, Switch, Paper } from "@material-ui/core";
import { useIntl, FormattedMessage } from "react-intl";
import { parseTranscript } from "../../utils";
import { TranscriptSection, TranscriptButton } from "../transcript";
import classNames from "classnames";
import LocalizedText from "../reusable/LocalizedText";

const useStyles = makeStyles(
  theme => ({
    transcript: {
      backgroundColor: theme.colors.popoverBackgroundColor,
      padding: "15px 20px 7px 20px",
      width: "800px"
    },
    controls: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center"
    },
    switches: {
      display: "flex",
      gap: "20px"
    },
    buttons: {
      display: "flex",
      gap: "10px"
    },
    transcriptSection: {
      margin: "8px 0"
    },
    divider: {
      borderTop: "1px solid #808080"
    }
  }),
  { name: "Transcript" }
);

export default function Transcript({
  data,
  filename,
  profanitiesDisplayed,
  onProfanitiesChange,
  spokenFormEnabled,
  onSpokenFormChange,
  className
}) {
  const classes = useStyles();
  const intl = useIntl();

  const transcript = useMemo(
    () =>
      parseTranscript(data, [], intl, profanitiesDisplayed, spokenFormEnabled),
    [data, intl, profanitiesDisplayed, spokenFormEnabled]
  );

  return (
    <Paper className={classNames(classes.transcript, className)}>
      <LocalizedText value="transcript" variant="h6" />
      <div className={classes.controls}>
        <div className={classes.switches}>
          <FormControlLabel
            control={
              <Switch
                checked={profanitiesDisplayed}
                onChange={onProfanitiesChange}
                color="primary"
              />
            }
            label={<FormattedMessage id="profanities" defaultMessage="???" />}
          />
          <FormControlLabel
            control={
              <Switch
                checked={spokenFormEnabled}
                onChange={onSpokenFormChange}
                color="primary"
              />
            }
            label={<FormattedMessage id="spokenForm" defaultMessage="???" />}
          />
        </div>
        <div className={classes.buttons}>
          <TranscriptButton type="copy" transcript={transcript}>
            <LocalizedText value="copy" />
          </TranscriptButton>
          <TranscriptButton
            type="download"
            filename={filename}
            transcript={transcript}
          >
            <LocalizedText value="download" />
          </TranscriptButton>
        </div>
      </div>
      {transcript.map((section, index) => (
        <Fragment key={index}>
          <TranscriptSection
            speakers={section.speakers}
            content={section.content}
            className={classes.transcriptSection}
          />
          {index < transcript.length - 1 && <div className={classes.divider} />}
        </Fragment>
      ))}
    </Paper>
  );
}
