import {
  USER_LOGIN,
  USER_LOGIN_FAIL,
  AUTHENTICATE_USER,
  AUTHENTICATE_USER_FAIL,
  CLEAR_LOGIN_ERROR,
  USER_LOGOUT,
  CONFERENCE_UPDATE,
  PARTICIPANTS_UPDATE,
  PARTICIPANTS_BUFFERED_UPDATE,
  PARTICIPANTS_SORT,
  USERS_UPDATE,
  DATA_CONF_ID_UPDATE,
  CALL_STATE_UPDATE,
  SET_TIMEOUT,
  PARTICIPANT_LIST_SHOW,
  PARTICIPANT_LIST_HIDE,
  PARTICIPANT_LIST_EXPAND,
  PARTICIPANT_LIST_COLLAPSE,
  WEBSOCKET_RECONNECT,
  INTERNAL_DRAWER_HIDE,
  INTERNAL_DRAWER_SHOW_PARTICIPANT_LIST,
  INTERNAL_DRAWER_SHOW_CHAT,
  INTERNAL_DRAWER_SHOW_VIDEO,
  INTERNAL_DRAWER_SHOW_CONFERENCE_PREFERENCES,
  INTERNAL_DRAWER_SHOW_TRANSCRIPT,
  WINDOW_RESIZE,
  LANGUAGE_UPDATE,
  THEME_UPDATE,
  STOP_SHARING,
  CHATS_UPDATE,
  CHAT_READ_STATE_UPDATE,
  USERID_UPDATE_FOR_CHAT_COUNT,
  USER_PRIORITY_UPDATE,
  USER_PRIORITY_AND_SELECTED_USER_UPDATE,
  SET_CONNECT_POPPER_EXPANSION,
  CONFERENCE_STATE_UPDATE,
  UPDATE_WEBRTC_CALL_STATE,
  HIDE_SETTINGS_MENU,
  HIDE_CONFERENCE_PREFERENCES_MENU,
  SHOW_SETTINGS_MENU,
  SET_CONTROLS_TAB_INDEX,
  SET_EXTENSION_AVAILABLE,
  SHOW_CONFERENCE_PREFERENCES_MENU,
  SET_AUDIO_INPUT,
  SET_AUDIO_OUTPUT,
  SET_SHARE_POPPER_EXPANSION,
  SET_PERSISTENT_SPACE_AND_CONFIGS,
  LOGIN_PORTAL_USER,
  LOGIN_PORTAL_USER_FAIL,
  AUTHENTICATE_PORTAL_USER,
  AUTHENTICATE_PORTAL_USER_FAIL,
  LOGOUT_PORTAL_USER,
  PORTAL_SELECT_VIEW,
  PORTAL_UPDATE_REPORT_CONFERENCE,
  PORTAL_UPDATE_REPORT_USER,
  PORTAL_UPDATE_REPORT_FEATURE_ACTION,
  PORTAL_UPDATE_CONFERENCE_RECORDINGS,
  PORTAL_REPORT_DATE_FROM,
  PORTAL_REPORT_DATE_TO,
  PORTAL_RECORDING_DATE_FROM,
  PORTAL_RECORDING_DATE_TO,
  PORTAL_DOWNLOAD_REPORT_DIALOG_VISIBLE,
  UPDATE_PORTAL_USER,
  SHAKE_CONF_SECURE_ICON,
  UPDATE_CURRENT_SELECTED_USER,
  SET_TALKER_INDICATOR_OPTION,
  SET_TALKER_INDICATOR_MAX_COUNT,
  SET_IS_LOGIN_FORM_SUBMITTING,
  SET_MAIN_ROOM,
  RESET_MAIN_ROOM,
  SET_SHARING_USER_ID,
  SET_VIDEO_INPUT,
  SET_BLUR,
  SET_WEBCAM_ON,
  SET_WEBCAM_OFF,
  SET_SELFVIEW_ON,
  SET_SELFVIEW_OFF,
  SET_AUDIO_ON,
  SET_AUDIO_OFF,
  SET_CHANGING_WEBCAM,
  SET_UPDATING_VIDEO_INPUT,
  SET_WEBCAM_BUTTON_ENABLED,
  SET_FULL_SCREEN_MODE,
  SET_HEADER_BARS_VISIBILITY,
  SET_HAMBURG_MENU_STATE,
  SET_MORE_POPPER_EXPANSION,
  SET_AUDIO_POPPER_EXPANSION,
  SET_SIGNAL_OPERATOR_POPPER_EXPANSION,
  SET_LOGOUT_MODAL_STATE,
  SET_HAS_HOST_JOINED,
  SET_IS_JOINED_TO_WAITING_ROOM,
  SET_USER_ID,
  SET_DIAL_IN_TOKEN,
  SET_CM_LOGGED_IN,
  ADD_TRANSCRIPT,
  SET_PARTIAL_TRANSCRIPT,
  ADD_TRANSLATION,
  SET_PARTIAL_TRANSLATION
} from "../actions/types";
import contexWebRest from "../api/contexWebRest";
import { SortDirection, isVideoEnabled, isCM } from "../utils";
import { LoginErrors } from "../errors";
import { UPDATE_INVITE_BY_PHONE_PARTICIPANT } from "../components/invite/actions";
import { InviteByPhoneParticipantState } from "../components/invite/";
import {
  UPDATE_QA_PARTY_STATE,
  UPDATE_QA_QUEUE,
  SET_DATA_QA_STATE,
  UPDATE_DATA_QA_ENTRY,
  REMOVE_DATA_QA_ENTRY
} from "../components/QA/actions";
import {
  setNotificationLevel,
  showNotificationWindow,
  setNotificationType,
  hideNotificationWindow,
  setNotificationMap
} from "../components/notification";
import { setDevices } from "../components/mediasoup/cookiesManager.js";
import Logger from "../Logger";
import { InternalDrawerState } from "../components/layouts/internalDrawerStates";
import { VideoPopoutState } from "../components/mediasoup/components/types";

const logger = new Logger("ActionCreators");

export const ServerErrorCode = {
  SERVER_ERROR: 0,
  INVALID_LOGIN: 1,
  INVALID_SESSION: 2,
  MAX_USER: 3,
  CONF_NOT_FOUND: 4,
  DUPLICATE_USER: 5,
  BAD_REQUEST: 6,
  BILLING_NOT_FOUND: 7,
  DUPLICATE_PERSISTENT_SPACE: 8,
  DUPLICATE_USERNAME: 9,
  PERSISTENT_SPACE_NOT_FOUND: 10,
  SELF_DELETE: 11
};

export const loginUser =
  (form, persistentSpaceName) => async (dispatch, getState) => {
    const {
      passcode,
      propertyID,
      agentID,
      pin,
      username,
      userDefined,
      userDefined2,
      userDefined3,
      userDefined4,
      recordingKey
    } = form;
    let requestBody = [];
    if (passcode != null) {
      requestBody.push(`passcode=${passcode}`);
    }
    if (propertyID != null) {
      requestBody.push(`&propertyID=${propertyID}`);
    }
    if (agentID != null) {
      requestBody.push(`&agentID=${agentID}`);
    }
    if (pin != null) {
      requestBody.push(`&pin=${pin}`);
    }
    if (username != null) {
      const encodedUsername = encodeURIComponent(username);
      requestBody.push(`&username=${encodedUsername}`);
    }
    if (userDefined != null) {
      const encodedUserDefined = encodeURIComponent(userDefined);
      requestBody.push(`&userDefined=${encodedUserDefined}`);
    }
    if (userDefined2 != null) {
      const encodedUserDefined2 = encodeURIComponent(userDefined2);
      requestBody.push(`&userDefined2=${encodedUserDefined2}`);
    }
    if (userDefined3 != null) {
      const encodedUserDefined3 = encodeURIComponent(userDefined3);
      requestBody.push(`&userDefined3=${encodedUserDefined3}`);
    }
    if (userDefined4 != null) {
      const encodedUserDefined4 = encodeURIComponent(userDefined4);
      requestBody.push(`&userDefined4=${encodedUserDefined4}`);
    }
    if (recordingKey != null) {
      const encodedRecordingKey = encodeURIComponent(recordingKey);
      requestBody.push(`&recordingKey=${encodedRecordingKey}`);
    }
    if (persistentSpaceName != null) {
      requestBody.push(`&persistentSpaceName=${persistentSpaceName}`);
    }
    requestBody = requestBody.join("");
    const headers = {
      "Content-Type": "application/x-www-form-urlencoded"
    };
    try {
      const response = await contexWebRest.post("/users/login", requestBody, {
        headers: headers
      });
      setDevices({
        webcamEnabled: getState().session.webcamEnabled
      });
      dispatch({
        type: USER_LOGIN,
        payload: {
          id: response.data.id,
          userId: response.data.userId,
          userLevel: response.data.userLevel,
          confName: response.data.confName,
          propertyID: response.data.propertyID,
          agentID: response.data.agentID,
          pin: response.data.pin,
          expandConnectPopper:
            window.CtxAppConfigurations.customer !== "TCAD" &&
            window.CtxAppConfigurations.expandConnectPopper,
          expandList:
            window.CtxAppConfigurations.customer !== "TCAD" &&
            window.innerWidth >= 900 &&
            window.CtxAppConfigurations.expandList,
          vetted: response.data.vetted
        }
      });
      return response.data.userId;
    } catch (error) {
      logger.error("Error: %o", error);
      dispatch({
        type: USER_LOGIN_FAIL,
        payload: { ...form, error: error.response.data.errorCode }
      });
    } finally {
      dispatch({
        type: SET_IS_LOGIN_FORM_SUBMITTING,
        payload: false
      });
    }
  };

export const logout = userId => async () => {
  logger.debug("log out user");
  try {
    await contexWebRest.post("/users/delete/" + userId);
  } catch (error) {
    logger.error("Error: %o", error);
  }
};

export const logoutUser =
  (history, persistentSpaceName, userRole, vettingConfig) => async dispatch => {
    if (userRole === "propertyOwner") {
      history.replace("/");
    } else if (userRole === "agent") {
      history.replace("/agent");
    } else if (
      persistentSpaceName != null &&
      persistentSpaceName.length !== 0 &&
      (!window.CtxAppConfigurations
        .loggedOutToGeneralLoginIfWaitingRoomDisabled ||
        vettingConfig)
    ) {
      history.replace("/" + persistentSpaceName);
    } else {
      history.replace("/");
    }

    dispatch({ type: USER_LOGOUT });
  };

export const endMeeting = userId => async () => {
  logger.debug("end meeting");
  try {
    await contexWebRest.post("/conference/destroy/" + userId);
  } catch (error) {
    logger.error("Error: %o", error);
  }
};

export const authenticateUser = userId => async dispatch => {
  const url = `/users/${userId}`;
  try {
    const response = await contexWebRest.get(url);
    dispatch({
      type: AUTHENTICATE_USER,
      payload: {
        id: response.data.id,
        userId: parseInt(userId),
        userLevel: response.data.userLevel,
        confName: response.data.confName,
        propertyID: response.data.propertyID,
        agentID: response.data.agentID,
        pin: response.data.pin,
        vetted: response.data.vetted
      }
    });
    return response.data.userId;
  } catch (error) {
    logger.error("Error: %o", error);
    if (error.response) {
      dispatch({
        type: AUTHENTICATE_USER_FAIL,
        payload: { error: error.response.data.errorCode }
      });
    } else {
      dispatch({
        type: AUTHENTICATE_USER_FAIL,
        payload: { error: LoginErrors.SESSION_INVALID }
      });
    }
  }
};

export const clearLoginError = () => ({
  type: CLEAR_LOGIN_ERROR
});

export const setUserID = userID => ({
  type: SET_USER_ID,
  payload: userID
});

export const authenticatePortalUser = sessionID => async dispatch => {
  const url = `/users/getPortalUser/${sessionID}`;
  try {
    const response = await contexWebRest.get(url);
    dispatch({
      type: AUTHENTICATE_PORTAL_USER,
      payload: {
        userID: sessionID
      }
    });
    dispatch({
      type: UPDATE_PORTAL_USER,
      payload: response.data
    });
  } catch (error) {
    logger.error("Error: %o", error);
    if (error.response) {
      dispatch({
        type: AUTHENTICATE_PORTAL_USER_FAIL,
        payload: { error: error.response.data.errorCode }
      });
    } else {
      dispatch({
        type: AUTHENTICATE_PORTAL_USER_FAIL,
        payload: { error: LoginErrors.SESSION_INVALID }
      });
    }
  }
};

export const sendPortalKeepalive = sessionID => async dispatch => {
  const url = `/users/portalUserKeepAlive/${sessionID}`;
  try {
    await contexWebRest.get(url);
  } catch (error) {
    logger.error("Error: %o", error);
    if (error.response) {
      dispatch({
        type: AUTHENTICATE_PORTAL_USER_FAIL,
        payload: { error: error.response.data.errorCode }
      });
      throw new Error();
    }
  }
};

export const getPortalUser = sessionID => async dispatch => {
  const url = `/users/getPortalUser/${sessionID}`;
  try {
    const response = await contexWebRest.get(url);
    dispatch({
      type: UPDATE_PORTAL_USER,
      payload: response.data
    });
  } catch (error) {
    logger.error("Error: %o", error);
  }
};

export const handleConferenceApiRequest = (userId, apiEndpoint) => async () => {
  logger.debug("conference %s %d", apiEndpoint, userId);

  const uri = `/conference/${apiEndpoint}/${userId}`;
  const requestBody = "reqSeq=1";
  const headers = {
    "Content-Type": "application/x-www-form-urlencoded"
  };
  try {
    await contexWebRest.post(uri, requestBody, {
      headers: headers
    });
  } catch (error) {
    logger.error("Error: %o", error);
  }
};

export const updateMaxDisplayedUsers =
  (userId, maxDisplayedUsers) => async () => {
    try {
      const uri = "/conference/update_max_displayed_users/" + userId;
      const headers = {
        "Content-Type": "application/x-www-form-urlencoded"
      };

      let requestBody = [];
      requestBody.push(`reqSeq=1`);
      if (maxDisplayedUsers != null) {
        requestBody.push(`&maxDisplayedUsers=${maxDisplayedUsers}`);
      }
      requestBody = requestBody.join("");

      await contexWebRest.post(uri, requestBody, {
        headers: headers
      });
    } catch (error) {
      logger.error("Error: %o", error);
    }
  };

export const updateParticipants = payload => async (dispatch, getState) => {
  const session = getState().session;
  if (
    payload.id &&
    payload.dataConfID &&
    payload.dataConfID === session.dataConfID
  ) {
    await contexWebRest.post(
      `/party/${payload.id}/associate/${session.userId}/1`
    );
    dispatch({
      type: DATA_CONF_ID_UPDATE,
      payload: null
    });
  }
  dispatch({
    type: PARTICIPANTS_UPDATE,
    payload
  });

  //InviteByParticipant
  const inviteByPhoneParticipant =
    getState().inviteParticipant.inviteByPhoneParticipant;
  if (
    payload.id &&
    inviteByPhoneParticipant &&
    inviteByPhoneParticipant.partyid &&
    payload.id === inviteByPhoneParticipant.partyid &&
    payload.connectState
  ) {
    const inviteByParticipant = {};

    switch (payload.connectState) {
      case "Connecting":
        inviteByParticipant.state = InviteByPhoneParticipantState.CALLING;
        break;
      case "Disconnecting":
        break;
      case "NotConnected":
        inviteByParticipant.state = InviteByPhoneParticipantState.ERROR;
        break;
      case "Removed":
        inviteByParticipant.state = InviteByPhoneParticipantState.INIT;
        inviteByParticipant.partyid = "";
        break;
      default:
        inviteByParticipant.state = InviteByPhoneParticipantState.CONNECTED;
        inviteByParticipant.partyid = "";
        break;
    }

    dispatch({
      type: UPDATE_INVITE_BY_PHONE_PARTICIPANT,
      payload: inviteByParticipant
    });
  }

  //QA
  if (payload.id) {
    if (payload.qaState) {
      dispatch({
        type: UPDATE_QA_PARTY_STATE,
        payload: {
          id: payload.id,
          qaState: payload.qaState,
          qaConfPosition: payload.qaConfPosition
        }
      });
    } else {
      if (payload.qaConfPosition && payload.qaConfPosition > 0) {
        dispatch({
          type: UPDATE_QA_QUEUE,
          payload: { id: payload.id, qaConfPosition: payload.qaConfPosition }
        });
      }
    }
  }
};

export const updateBufferedParticipants = payload => {
  return {
    type: PARTICIPANTS_BUFFERED_UPDATE,
    payload
  };
};

export const updateConference = payload => {
  return {
    type: CONFERENCE_UPDATE,
    payload
  };
};

export const updateUsers = payload => {
  return {
    type: USERS_UPDATE,
    payload
  };
};

export const userLogin = payload => ({
  type: USER_LOGIN,
  payload: {
    ...payload,
    expandList: window.innerWidth >= 900 && payload.expandList
  }
});

export const showParticipantList = payload => {
  return {
    type: PARTICIPANT_LIST_SHOW,
    payload
  };
};

export const hideParticipantList = payload => {
  return {
    type: PARTICIPANT_LIST_HIDE,
    payload
  };
};

export const expandParticipantList = payload => {
  return {
    type: PARTICIPANT_LIST_EXPAND,
    payload
  };
};

export const collapseParticipantList = payload => {
  return {
    type: PARTICIPANT_LIST_COLLAPSE,
    payload
  };
};

export const createDataConfIDParty = (party, userid) => async dispatch => {
  // If the username is lost due to a page refresh, use "Party Name"
  if (!party.name) {
    party.name = "Party Name";
  }
  const requestBody = party;
  const headers = {
    "Content-Type": "application/json"
  };
  try {
    const response = await contexWebRest.post(
      "/party/create_data_conference_id_party/" +
        userid +
        "/1?promptEnabled=false",
      requestBody,
      {
        headers: headers
      }
    );
    dispatch({
      type: DATA_CONF_ID_UPDATE,
      payload: response.data.dataConfID
    });
    return response.data.dataConfID;
  } catch (error) {
    logger.error(
      "Error: %o -- while making create data conference id network request",
      error
    );
  }
};

export const createAndCallParty =
  (party, userid) => async (dispatch, getState) => {
    const requestBody = party;
    const headers = {
      "Content-Type": "application/json"
    };
    try {
      let uri =
        "/party/create_party_call/" +
        userid +
        "/1?join=true&forceDisconnect=true&blocking=true&associate=true";
      if (isCM()) {
        uri += "&callMe=true";
      }

      const response = await contexWebRest.post(uri, requestBody, {
        headers: headers
      });
      return response.data.id;
    } catch (error) {
      dispatch({
        type: CALL_STATE_UPDATE,
        payload: "NotConnected"
      });
      clearTimeout(getState().session.timeout);
      logger.error("Error: %o -- while creating and calling the party", error);
    }
  };

export const updateCallState = payload => dispatch => {
  if (payload === "Connecting") {
    const timer = setTimeout(function () {
      dispatch({
        type: CALL_STATE_UPDATE,
        payload: "NotConnected"
      });
    }, 30000);
    dispatch({
      type: SET_TIMEOUT,
      payload: timer
    });
  }
  dispatch({
    type: CALL_STATE_UPDATE,
    payload
  });
};

export const disconnectParty = (partyID, userid) => async () => {
  const headers = {
    "Content-Type": "application/x-www-form-urlencoded"
  };
  try {
    await contexWebRest.post(
      "/party/" + partyID + "/disconnect/" + userid,
      null,
      {
        headers: headers
      }
    );
  } catch (error) {
    logger.error("Error: %o -- while disconnecting the connected party", error);
  }
};

export const sortParticipants = payload => {
  const sort = {};
  //TODO handle passing in direction as well
  sort.by = payload;
  sort.direction = SortDirection.TOGGLE;
  return {
    type: PARTICIPANTS_SORT,
    payload: { sort }
  };
};

export const websocketReconnect = () => {
  return {
    type: WEBSOCKET_RECONNECT
  };
};

export const hideInternalDrawer = payload => {
  return {
    type: INTERNAL_DRAWER_HIDE,
    payload
  };
};

export const displayParticipantListInternalDrawer = payload => {
  return {
    type: INTERNAL_DRAWER_SHOW_PARTICIPANT_LIST,
    payload
  };
};

export const displayChatInternalDrawer = payload => {
  return {
    type: INTERNAL_DRAWER_SHOW_CHAT,
    payload
  };
};

export const displayConferencePreferencesInternalDrawer = payload => ({
  type: INTERNAL_DRAWER_SHOW_CONFERENCE_PREFERENCES,
  payload
});

export const displayTranscriptInternalDrawer = payload => ({
  type: INTERNAL_DRAWER_SHOW_TRANSCRIPT,
  payload
});

export const resizeWindow = (height, width) => {
  return {
    type: WINDOW_RESIZE,
    payload: { height: height, width: width }
  };
};

export const languageUpdate = language => async dispatch => {
  let lang = language;
  const url = `/languages/translations/`;

  try {
    const response = await contexWebRest.get(url);

    if (response.data[lang] === undefined) {
      lang = Object.keys(response.data)[0]; //default language code
    }
    dispatch({
      type: LANGUAGE_UPDATE,
      payload: { languageCode: lang, translations: response.data[lang] }
    });
    return response.data[lang];
  } catch (error) {
    logger.error("Error: %o", error);
  }
};

export const updateTheme = payload => {
  return {
    type: THEME_UPDATE,
    payload
  };
};

export const stopSharing = data => async (dispatch, getState) => {
  if (
    getState().internalDrawer === InternalDrawerState.VIDEO &&
    window.innerWidth >= 900
  ) {
    dispatch({ type: INTERNAL_DRAWER_HIDE });
  }

  dispatch({
    type: STOP_SHARING,
    payload: { data: data }
  });
};

export const updateChats = payload => {
  return {
    type: CHATS_UPDATE,
    payload
  };
};

export const updateChatReadState = (chatId, senderId, status) => {
  return {
    type: CHAT_READ_STATE_UPDATE,
    payload: { chatId: chatId, senderId: senderId, status: status }
  };
};

export const updateSelectedUserIdForChatCount = payload => {
  return {
    type: USERID_UPDATE_FOR_CHAT_COUNT,
    payload
  };
};

export const updateUserPriority = userId => {
  return {
    type: USER_PRIORITY_UPDATE,
    payload: { userId: userId }
  };
};

export const updateSelectedUserAndPriority = (userId, userName) => {
  return {
    type: USER_PRIORITY_AND_SELECTED_USER_UPDATE,
    payload: { userId: userId, userName: userName }
  };
};

export const setConnectPopperExpansion = payload => {
  return {
    type: SET_CONNECT_POPPER_EXPANSION,
    payload
  };
};

export const updateConferenceState =
  (data, isHostView) => async (dispatch, getState) => {
    const state = getState();

    if (
      isVideoEnabled(data.videoConfig) &&
      !state.session.sharingActive &&
      data.sharingActive &&
      state.internalDrawer !== InternalDrawerState.VIDEO &&
      state.videoPopoutWindow.windowStatus !== VideoPopoutState.OPEN &&
      window.innerWidth >= 900
    ) {
      dispatch({ type: INTERNAL_DRAWER_SHOW_VIDEO });
    }

    dispatch({
      type: CONFERENCE_STATE_UPDATE,
      payload: {
        data: data,
        isHostView: isHostView
      }
    });
  };

export const updateWebRTCCallState = payload => ({
  type: UPDATE_WEBRTC_CALL_STATE,
  payload
});

export const hideSettingsMenu = payload => {
  return {
    type: HIDE_SETTINGS_MENU,
    payload
  };
};

export const hideConferencePreferencesMenu = payload => ({
  type: HIDE_CONFERENCE_PREFERENCES_MENU,
  payload
});

export const showSettingsMenu = payload => {
  return {
    type: SHOW_SETTINGS_MENU,
    payload
  };
};

export const setControlsTabView = selectedView => ({
  type: SET_CONTROLS_TAB_INDEX,
  payload: selectedView
});

export const showConferencePreferencesMenu = payload => ({
  type: SHOW_CONFERENCE_PREFERENCES_MENU,
  payload
});

export const setAudioInput = payload => async dispatch => {
  try {
    if (window.stream != null) {
      window.stream.getTracks().forEach(track => {
        track.stop();
      });
      window.stream = await navigator.mediaDevices.getUserMedia({
        audio: {
          deviceId: payload
        }
      });
    }

    if (window.webRTCSession != null) {
      const stream = await navigator.mediaDevices.getUserMedia({
        audio: {
          deviceId: payload
        }
      });
      const audioTrack = stream.getAudioTracks()[0];
      const pc = window.webRTCSession.connection;
      const sender = pc.getSenders().find(function (s) {
        return s.track.kind === audioTrack.kind;
      });
      sender.track.stop();
      sender.replaceTrack(audioTrack);
    }
  } catch (error) {
    logger.error("Error: %o", error);
  }
  dispatch({
    type: SET_AUDIO_INPUT,
    payload
  });
};

export const setAudioOutput = sinkId => async dispatch => {
  const audioElement = document.getElementById("remoteMedia");
  const webRTCAudioElement = document.getElementById("webRTCMedia");
  try {
    await audioElement.setSinkId(sinkId);
    if (window.webRTCSession != null) {
      await webRTCAudioElement.setSinkId(sinkId);
    }
    logger.debug("Audio output device attached: %s", sinkId);
  } catch (error) {
    logger.error("Error: %o", error);
  }
  dispatch({
    type: SET_AUDIO_OUTPUT,
    payload: sinkId
  });
};

export const setVideoInput = videoInput => {
  return {
    type: SET_VIDEO_INPUT,
    payload: videoInput
  };
};

export const setBlur = blur => {
  localStorage.setItem("backgroundBlur", blur);
  return {
    type: SET_BLUR,
    payload: blur
  };
};

export const setExtensionAvailable = payload => ({
  type: SET_EXTENSION_AVAILABLE,
  payload
});

export const setCMLoggedIn = cmLoggedIn => {
  sessionStorage.setItem("CMSSLoggedIn", cmLoggedIn);
  return {
    type: SET_CM_LOGGED_IN,
    payload: cmLoggedIn
  };
};

export const setTalkerIndicatorOption = payload => {
  return {
    type: SET_TALKER_INDICATOR_OPTION,
    payload
  };
};

export const setTalkerIndicatorMaxCount = payload => {
  return {
    type: SET_TALKER_INDICATOR_MAX_COUNT,
    payload
  };
};

export const setSharePopperExpansion = payload => {
  return {
    type: SET_SHARE_POPPER_EXPANSION,
    payload
  };
};

export const setPersistentSpaceAndConfigs = (
  name,
  webRTCCallConfig,
  videoConfig,
  pinRequired
) => {
  return {
    type: SET_PERSISTENT_SPACE_AND_CONFIGS,
    payload: {
      name: name,
      webRTCCallConfig: webRTCCallConfig,
      videoConfig: videoConfig,
      pinRequired: pinRequired
    }
  };
};

export const loginPortalUser = (username, password) => async dispatch => {
  const uri = `/users/portalLogin`;
  const encodedPassword = encodeURIComponent(password);
  const requestBody = `username=${username}&password=${encodedPassword}`;
  const headers = {
    "Content-Type": "application/x-www-form-urlencoded"
  };
  try {
    const response = await contexWebRest.post(uri, requestBody, {
      headers: headers
    });
    dispatch({
      type: LOGIN_PORTAL_USER,
      payload: response.data
    });
    dispatch({
      type: UPDATE_PORTAL_USER,
      payload: response.data
    });
    return response.data.userID;
  } catch (error) {
    logger.error("Error: %o -- while login the portal user");
    if (error.response) {
      dispatch({
        type: LOGIN_PORTAL_USER_FAIL,
        payload: { error: error.response.data.errorCode }
      });
    } else {
      dispatch({
        type: LOGIN_PORTAL_USER_FAIL,
        payload: { error: LoginErrors.SESSION_INVALID }
      });
    }
  }
};

export const logoutPortalUser = sessionID => async dispatch => {
  logger.debug("log out portal user: %d", sessionID);
  try {
    await contexWebRest.post("/users/deletePortalUser/" + sessionID);
    dispatch({
      type: LOGOUT_PORTAL_USER
    });
  } catch (error) {
    logger.error("Error: %o", error);
  }
};

export const selectPortalView = selectedView => {
  return {
    type: PORTAL_SELECT_VIEW,
    payload: selectedView
  };
};

export const getPortalHostBillingConferences =
  (sessionID, startDateTime, endDateTime) => async dispatch => {
    logger.debug("request host billing conferences: %d", sessionID);

    try {
      const response = await contexWebRest.get(
        "/billing/" +
          sessionID +
          "/conference?startDateTime=" +
          startDateTime +
          "&endDateTime=" +
          endDateTime
      );
      dispatch({
        type: PORTAL_UPDATE_REPORT_CONFERENCE,
        payload: response.data
      });
    } catch (error) {
      logger.error("Error: %o", error);
    }
  };

export const resetPortalBillingConferences = () => {
  return {
    type: PORTAL_UPDATE_REPORT_CONFERENCE,
    payload: undefined
  };
};

export const resetPortalRecordings = () => {
  return {
    type: PORTAL_UPDATE_CONFERENCE_RECORDINGS,
    payload: undefined
  };
};

export const getPortalHostBillingUsers =
  (sessionID, conferenceKey) => async dispatch => {
    logger.debug(
      "request host billing users. sessionId=%d conferenceKey=%d",
      sessionID,
      conferenceKey
    );

    try {
      const response = await contexWebRest.get(
        "/billing/" + sessionID + "/conference/" + conferenceKey + "/users"
      );
      dispatch({
        type: PORTAL_UPDATE_REPORT_USER,
        payload: response.data
      });
    } catch (error) {
      logger.error("Error: %o", error);
    }
  };

export const resetPortalBillingUsers = () => {
  return {
    type: PORTAL_UPDATE_REPORT_USER,
    payload: undefined
  };
};

export const getPortalHostBillingFeatureActions =
  (sessionID, conferenceKey) => async dispatch => {
    logger.debug(
      "request host billing feature actions. sessionId=%d conferenceKey=%d",
      sessionID,
      conferenceKey
    );

    try {
      const response = await contexWebRest.get(
        "/billing/" + sessionID + "/conference/" + conferenceKey + "/actions"
      );
      dispatch({
        type: PORTAL_UPDATE_REPORT_FEATURE_ACTION,
        payload: response.data
      });
    } catch (error) {
      logger.error("Error: %o", error);
    }
  };

export const resetPortalBillingFeatureActions = () => {
  return {
    type: PORTAL_UPDATE_REPORT_FEATURE_ACTION,
    payload: undefined
  };
};

export const getTranscript = (viewID, conferenceKey) => async () => {
  try {
    return await contexWebRest.get(
      `/billing/conference/${conferenceKey}/transcript?viewID=${viewID}`
    );
  } catch (error) {
    logger.error("Error: %o", error);
  }
};

export const setPortalReportDateFrom = date => {
  return {
    type: PORTAL_REPORT_DATE_FROM,
    payload: date
  };
};

export const setPortalReportDateTo = date => {
  return {
    type: PORTAL_REPORT_DATE_TO,
    payload: date
  };
};

export const setPortalRecordingDateFrom = date => {
  return {
    type: PORTAL_RECORDING_DATE_FROM,
    payload: date
  };
};

export const setPortalRecordingDateTo = date => {
  return {
    type: PORTAL_RECORDING_DATE_TO,
    payload: date
  };
};

export const getPortalConferenceRecordings =
  (sessionID, startDateTime, endDateTime) => async dispatch => {
    logger.debug("request host conference recordings: %d", sessionID);

    try {
      const response = await contexWebRest.get(
        "/billing/" +
          sessionID +
          "/conferenceRecordings?startDateTime=" +
          startDateTime +
          "&endDateTime=" +
          endDateTime
      );
      dispatch({
        type: PORTAL_UPDATE_CONFERENCE_RECORDINGS,
        payload: response.data
      });
    } catch (error) {
      logger.error("Error: %o", error);
    }
  };

export const setPortalDownloadReportDialogWindowVisible = isVisible => {
  return {
    type: PORTAL_DOWNLOAD_REPORT_DIALOG_VISIBLE,
    payload: isVisible
  };
};

export const setShakeConferenceSecureIcon = isShaking => {
  return {
    type: SHAKE_CONF_SECURE_ICON,
    payload: isShaking
  };
};

export const updateCurrentSelectedUser = (userId, userName) => {
  return {
    type: UPDATE_CURRENT_SELECTED_USER,
    payload: { userId: userId, userName: userName }
  };
};

export const setDataQAState = dataQAState => {
  return {
    type: SET_DATA_QA_STATE,
    payload: dataQAState
  };
};

export const updateDataQAEntry = dataQAEntry => {
  return {
    type: UPDATE_DATA_QA_ENTRY,
    payload: dataQAEntry
  };
};

export const removeDataQAEntry = entryId => {
  return {
    type: REMOVE_DATA_QA_ENTRY,
    payload: entryId
  };
};

export const notifyServerUsingWebRTCCall =
  (userid, isUsingWebRTCCall) => async () => {
    try {
      const uri = "/users/setWebRTCCall/" + userid;
      const headers = {
        "Content-Type": "application/x-www-form-urlencoded"
      };
      const requestBody = `reqSeq=1&isWebRTCCall=${isUsingWebRTCCall}`;
      await contexWebRest.post(uri, requestBody, {
        headers: headers
      });
    } catch (error) {
      logger.error("Error: %o", error);
    }
  };

export const setIsLoginFormSubmitting = isSubmitting => {
  return {
    type: SET_IS_LOGIN_FORM_SUBMITTING,
    payload: isSubmitting
  };
};

export const updateNotification = notificationData => async dispatch => {
  const { level, type, map } = notificationData;

  dispatch(hideNotificationWindow());
  dispatch(setNotificationLevel(level));
  dispatch(setNotificationType(type));
  dispatch(setNotificationMap(map));
  dispatch(showNotificationWindow());
};

export const disablePresenterMode = (userId, targetUserId) => async () => {
  try {
    const uri = "/users/disablePresenterMode/" + userId;
    const headers = {
      "Content-Type": "application/x-www-form-urlencoded"
    };
    const requestBody = `reqSeq=1&targetUserId=${targetUserId}`;
    await contexWebRest.post(uri, requestBody, {
      headers: headers
    });
  } catch (error) {
    logger.error("Error: %o", error);
  }
};

export const enablePresenterMode = (userId, targetUserId) => async () => {
  try {
    const uri = "/users/enablePresenterMode/" + userId;
    const headers = {
      "Content-Type": "application/x-www-form-urlencoded"
    };
    const requestBody = `reqSeq=1&targetUserId=${targetUserId}`;
    await contexWebRest.post(uri, requestBody, {
      headers: headers
    });
  } catch (error) {
    logger.error("Error: %o", error);
  }
};

export const enableRemoteControl = (userId, targetUserId) => async () => {
  try {
    const uri = "/users/enable_remote_control/" + userId;
    const headers = {
      "Content-Type": "application/x-www-form-urlencoded"
    };
    const requestBody = `targetUserID=${targetUserId}`;
    await contexWebRest.post(uri, requestBody, {
      headers: headers
    });
  } catch (error) {
    logger.error("Error: %o", error);
  }
};

export const disableRemoteControl = userId => async () => {
  try {
    const uri = "/users/disable_remote_control/" + userId;
    const headers = {
      "Content-Type": "application/x-www-form-urlencoded"
    };
    await contexWebRest.post(uri, null, {
      headers: headers
    });
  } catch (error) {
    logger.error("Error: %o", error);
  }
};

export const lockAudioMode = (userId, targetUserId) => async () => {
  try {
    const uri = "/users/lock_audio_mode/" + userId;
    const headers = {
      "Content-Type": "application/x-www-form-urlencoded"
    };
    const requestBody = `reqSeq=1&targetUserId=${targetUserId}`;
    await contexWebRest.post(uri, requestBody, {
      headers: headers
    });
  } catch (error) {
    logger.error("Error: %o", error);
  }
};

export const unlockAudioMode = (userId, targetUserId) => async () => {
  try {
    const uri = "/users/unlock_audio_mode/" + userId;
    const headers = {
      "Content-Type": "application/x-www-form-urlencoded"
    };
    const requestBody = `reqSeq=1&targetUserId=${targetUserId}`;
    await contexWebRest.post(uri, requestBody, {
      headers: headers
    });
  } catch (error) {
    logger.error("Error: %o", error);
  }
};

export const pinVideo = (userId, targetUserId) => async () => {
  try {
    const uri = "/users/pin_video/" + userId;
    const headers = {
      "Content-Type": "application/x-www-form-urlencoded"
    };
    const requestBody = `reqSeq=1&targetUserId=${targetUserId}`;
    await contexWebRest.post(uri, requestBody, {
      headers: headers
    });
  } catch (error) {
    logger.error("Error: %o", error);
  }
};

export const unpinVideo = (userId, targetUserId) => async () => {
  try {
    const uri = "/users/unpin_video/" + userId;
    const headers = {
      "Content-Type": "application/x-www-form-urlencoded"
    };
    const requestBody = `reqSeq=1&targetUserId=${targetUserId}`;
    await contexWebRest.post(uri, requestBody, {
      headers: headers
    });
  } catch (error) {
    logger.error("Error: %o", error);
  }
};

export const handleSignalOperatorRequest = (userId, signal) => async () => {
  logger.debug("signal operator %d", userId);

  const uri = `/users/signal_operator/${userId}?signal=${signal}`;
  const requestBody = "reqSeq=1";
  const headers = {
    "Content-Type": "application/x-www-form-urlencoded"
  };
  try {
    await contexWebRest.post(uri, requestBody, {
      headers: headers
    });
  } catch (error) {
    logger.error("Error: %o", error);
  }
};

export const disableConferencePresenterMode = userId => async () => {
  try {
    const uri = "/conference/disablePresenterMode/" + userId;
    const headers = {
      "Content-Type": "application/x-www-form-urlencoded"
    };
    const requestBody = `reqSeq=1`;
    await contexWebRest.post(uri, requestBody, {
      headers: headers
    });
  } catch (error) {
    logger.error("Error: %o", error);
  }
};

export const displayVideoInternalDrawer = payload => {
  return {
    type: INTERNAL_DRAWER_SHOW_VIDEO,
    payload
  };
};

export const setSharingUserId = userId => {
  return {
    type: SET_SHARING_USER_ID,
    payload: userId
  };
};

export const stopScreenShare = (userId, targetUserId) => async () => {
  try {
    const uri = "/users/stopScreenShare/" + userId;
    const headers = {
      "Content-Type": "application/x-www-form-urlencoded"
    };
    const requestBody = `reqSeq=1&targetUserId=${targetUserId}`;
    await contexWebRest.post(uri, requestBody, {
      headers: headers
    });
  } catch (error) {
    logger.error("Error: %o", error);
  }
};

export const setMainRoom = payload => {
  return {
    type: SET_MAIN_ROOM,
    payload
  };
};

export const resetMainRoom = payload => {
  return {
    type: RESET_MAIN_ROOM,
    payload
  };
};

export const setWebCamOn = payload => {
  return {
    type: SET_WEBCAM_ON,
    payload
  };
};

export const setWebCamOff = payload => {
  return {
    type: SET_WEBCAM_OFF,
    payload
  };
};

export const setSelfViewOn = payload => ({
  type: SET_SELFVIEW_ON,
  payload
});

export const setSelfViewOff = payload => ({
  type: SET_SELFVIEW_OFF,
  payload
});

export const setAudioOn = payload => {
  return {
    type: SET_AUDIO_ON,
    payload
  };
};

export const setAudioOff = payload => {
  return {
    type: SET_AUDIO_OFF,
    payload
  };
};

export const setChangingWebcam = value => {
  return {
    type: SET_CHANGING_WEBCAM,
    payload: value
  };
};

export const setUpdatingVideoInput = value => {
  return {
    type: SET_UPDATING_VIDEO_INPUT,
    payload: value
  };
};

export const setWebcamButtonEnabled = value => {
  return {
    type: SET_WEBCAM_BUTTON_ENABLED,
    payload: value
  };
};

export const deleteUser = (userID, targetUserID) => async () => {
  try {
    let requestBody;
    if (targetUserID) {
      requestBody = `userIds[]=${targetUserID}`;
    }
    const headers = {
      "Content-Type": "application/x-www-form-urlencoded"
    };
    await contexWebRest.post("/users/delete/" + userID, requestBody, {
      headers: headers
    });
  } catch (error) {
    logger.error("Error: %o", error);
  }
};

export const transferUser =
  (userSessionID, userid, newPasscode) => async () => {
    if (userid == null || userSessionID == null) {
      return;
    }

    try {
      const requestBody = `userIds[]=${userid}&transferPasscode=${newPasscode}`;
      const headers = {
        "Content-Type": "application/x-www-form-urlencoded"
      };
      await contexWebRest.post(
        "/users/transfer/" + userSessionID,
        requestBody,
        {
          headers: headers
        }
      );
    } catch (error) {
      logger.error("Error: %o", error);
    }
  };

export const setFullScreenMode = value => {
  return {
    type: SET_FULL_SCREEN_MODE,
    payload: value
  };
};

export const setHeaderBarsVisibility = value => {
  return {
    type: SET_HEADER_BARS_VISIBILITY,
    payload: value
  };
};

export const setHamburgMenuState = value => {
  return {
    type: SET_HAMBURG_MENU_STATE,
    payload: value
  };
};

export const setLogoutModalState = value => {
  return {
    type: SET_LOGOUT_MODAL_STATE,
    payload: value
  };
};

export const setMorePopperExpansion = payload => {
  return {
    type: SET_MORE_POPPER_EXPANSION,
    payload
  };
};

export const setAudioPopperExpansion = payload => {
  return {
    type: SET_AUDIO_POPPER_EXPANSION,
    payload
  };
};

export const setSignalOperatorPopperExpansion = payload => {
  return {
    type: SET_SIGNAL_OPERATOR_POPPER_EXPANSION,
    payload
  };
};

export const setHasHostJoined = () => {
  return {
    type: SET_HAS_HOST_JOINED
  };
};

export const setIsJoinedToWaitingRoom = isJoinedToWaitingRoom => {
  return {
    type: SET_IS_JOINED_TO_WAITING_ROOM,
    payload: isJoinedToWaitingRoom
  };
};

export const admitUser = (userId, targetUserId) => async () => {
  try {
    const uri = "/users/admit_user/" + userId;
    const headers = {
      "Content-Type": "application/x-www-form-urlencoded"
    };
    const requestBody = `reqSeq=1&targetUserId=${targetUserId}`;
    await contexWebRest.post(uri, requestBody, {
      headers: headers
    });
  } catch (error) {
    logger.error("Error: %o", error);
  }
};

export const demitUser = (userId, targetUserId) => async () => {
  try {
    const uri = "/users/demit_user/" + userId;
    const headers = {
      "Content-Type": "application/x-www-form-urlencoded"
    };
    const requestBody = `targetUserId=${targetUserId}`;
    await contexWebRest.post(uri, requestBody, {
      headers: headers
    });
  } catch (error) {
    logger.error("Error: %o", error);
  }
};

export const admitAllUsers = (userId, targetUserIds) => async () => {
  try {
    let userIds = "";

    if (targetUserIds instanceof Array) {
      for (let i = 0; i < targetUserIds.length; i++) {
        userIds += "targetUserId[]=" + targetUserIds[i];
        if (i < targetUserIds.length - 1) {
          userIds += "&";
        }
      }
    }

    const uri = "/users/admit_all_users/" + userId;
    const headers = {
      "Content-Type": "application/x-www-form-urlencoded"
    };
    const requestBody = `reqSeq=1&${userIds}`;
    await contexWebRest.post(uri, requestBody, {
      headers: headers
    });
  } catch (error) {
    logger.error("Error: %o", error);
  }
};

export const handleRejectUser = (userId, targetUserId) => async () => {
  try {
    const uri = "/users/reject_user/" + userId;
    const headers = {
      "Content-Type": "application/x-www-form-urlencoded"
    };
    const requestBody = `reqSeq=1&targetUserId=${targetUserId}`;
    await contexWebRest.post(uri, requestBody, {
      headers: headers
    });
  } catch (error) {
    logger.error("Error: %o", error);
  }
};

export const setCMDialInToken = data => ({
  type: SET_DIAL_IN_TOKEN,
  payload: data
});

export const handleParticipantApiRequest =
  (partyId, userId, apiEndpoint) => async () => {
    logger.debug("party %s %s %d", apiEndpoint, partyId, userId);

    const uri = `/party/${apiEndpoint}/${userId}`;
    const requestBody = `partyIds[]=${partyId}&reqSeq=1`;
    const headers = {
      "Content-Type": "application/x-www-form-urlencoded"
    };
    try {
      await contexWebRest.post(uri, requestBody, {
        headers: headers
      });
    } catch (error) {
      logger.error("Error: %o", error);
    }
  };

export const addTranscript = data => ({
  type: ADD_TRANSCRIPT,
  payload: data
});

export const setPartialTranscript = data => ({
  type: SET_PARTIAL_TRANSCRIPT,
  payload: data
});

export const addTranslation = translation => ({
  type: ADD_TRANSLATION,
  payload: translation
});

export const setPartialTranslation = translation => ({
  type: SET_PARTIAL_TRANSLATION,
  payload: translation
});
