import React, { Component } from "react";
import { withStyles, withTheme } from "@material-ui/core/styles";
import { connect } from "react-redux";
import classNames from "classnames";
import PortalReportConferenceTable from "./PortalReportConferenceTable";
import PortalReportUserTable from "./PortalReportUserTable";
import PortalReportFeatureActionTable from "./PortalReportFeatureActionTable";
import PortalHeader from "./PortalHeader";
import DownloadReportDialogWindow from "./DownloadReportDialogWindow";
import { getTranscript } from "../../actions";
import Transcript from "./Transcript";

const styles = theme => ({
  root: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    backgroundColor: theme.colors.primaryBackgroundColor
  },
  scrollBar: {
    "&::-webkit-scrollbar": {
      width: "8px",
      height: "8px"
    },
    "&::-webkit-scrollbar-thumb": {
      background: theme.colors.secondaryMainColor,
      borderRadius: "4px",
      border: "none"
    },
    "&::-webkit-scrollbar-track-piece": {
      background: theme.colors.sideBarBackgroundColor,
      borderRadius: "4px"
    },
    "&::-webkit-scrollbar-corner": {
      background: theme.colors.sideBarBackgroundColor
    },
    scrollbarColor: `${theme.colors.secondaryMainColor} ${theme.colors.popoverBackgroundColor}`,
    scrollbarWidth: "thin"
  },
  header: {
    minWidth: "1160px",
    height: "50px",
    flexDirection: "row",
    margin: "10px 20px 10px 20px",
    backgroundColor: theme.colors.popoverBackgroundColor
  },
  mainContentContainer: {
    overflow: "auto",
    display: "flex",
    flexDirection: "column",
    margin: "10px 15px 10px 15px"
  },
  mainContentMidContainer: {
    minWidth: "1160px",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
  },
  mainContentBottomContainer: {
    minWidth: "1160px",
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "stretch"
  },
  participantTableContainer: {
    order: 1,
    width: "58%"
  },
  actionTableContainer: {
    order: 2,
    width: "42%"
  },
  transcript: {
    marginBottom: "10px"
  }
});

class PortalReport extends Component {
  state = {
    transcriptData: [],
    conferenceName: "",
    conferenceStartDatetime: new Date(),
    profanitiesDisplayed: false,
    spokenFormEnabled: false
  };

  handleRowClick = async rowData => {
    const response = await this.props.getTranscript(
      this.props.session.portalSessionId,
      rowData.conferenceKey
    );

    if (response != null) {
      this.setState({
        transcriptData: response.data.data,
        conferenceName: rowData.conferenceName,
        conferenceStartDatetime: rowData.utcStartDateTime
      });
    } else {
      this.setState({
        transcriptData: [],
        conferenceName: "",
        conferenceStartDatetime: new Date()
      });
    }
  };

  handleProfanitiesChange = event => {
    this.setState({ profanitiesDisplayed: event.target.checked });
  };

  handleSpokenFormChange = event => {
    this.setState({ spokenFormEnabled: event.target.checked });
  };

  render() {
    const { classes, session } = this.props;

    return (
      <div className={classes.root}>
        <div className={classes.header}>
          <PortalHeader
            title={
              session.portalUser && session.portalUser.admin
                ? "allReports"
                : "myReports"
            }
          />
        </div>
        <div
          className={classNames(
            classes.mainContentContainer,
            classes.scrollBar
          )}
        >
          <div className={classes.mainContentMidContainer}>
            <PortalReportConferenceTable onRowClick={this.handleRowClick} />
            {this.props.portal.downloadReportDialogVisible && (
              <DownloadReportDialogWindow />
            )}
          </div>
          <div className={classes.mainContentBottomContainer}>
            <div className={classes.participantTableContainer}>
              <PortalReportUserTable />
            </div>
            <div className={classes.actionTableContainer}>
              <PortalReportFeatureActionTable />
            </div>
          </div>
          {this.state.transcriptData.length > 0 && (
            <Transcript
              data={this.state.transcriptData}
              filename={
                this.state.conferenceName +
                " " +
                this.state.conferenceStartDatetime.toLocaleString("en-US", {
                  timeZoneName: "short"
                }) +
                ".txt"
              }
              profanitiesDisplayed={this.state.profanitiesDisplayed}
              onProfanitiesChange={this.handleProfanitiesChange}
              spokenFormEnabled={this.state.spokenFormEnabled}
              onSpokenFormChange={this.handleSpokenFormChange}
              className={classes.transcript}
            />
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ portal, session }) => ({ portal, session });

const mapDispatchToProps = {
  getTranscript
};

export default withTheme(
  withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(PortalReport))
);
